export const required = (v: string | string[]): string | boolean => {
  if (Array.isArray(v)) return v?.length > 0 || "Campo obbligatorio";
  return !!v || "Campo obbligatorio";
};

export const email = (v: string): string | boolean =>
  !v ||
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
  "Email non valida";

export const passwordLength = (psw: string): string | boolean =>
  psw == null || psw.length >= 8 || "Minimo 8 caratteri";

export const isPassword = (psw: string): string | boolean =>
  psw != "password" || "Password non sicura";

export const passwordCheck = (psw1: string, psw2: string): string | boolean =>
  psw1 == psw2 || "Password differenti";
